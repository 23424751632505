<template>
    <div class="encaissement_page">
        <template v-if="admin">
            <cDate month="1" v-model="select_date" class="w-full select-large"/>
            <br/>
            <vs-row>
                <vs-col vs-xs="12" vs-sm="12" vs-lg="6" class="espace-left">
                    <vx-card style="margin-right:20px;height:300px;">
                        <h3 style="text-align:center;">Encaissements / Différés</h3>
                        <br/>
                        <hr/>
                        <br/><br/>
                        <vue-apex-charts ref="donut" type="donut" height="200" :options="donutChart.chartOptions" :series="donutChart.series"></vue-apex-charts>
                        <br/>
                    </vx-card>
                </vs-col>
                <vs-col vs-xs="12" vs-sm="12" vs-lg="6" class="espace-right">
                    <vx-card style="margin-left:20px;height:300px;">
                        <h3 style="text-align:center;">Modes de paiement</h3>
                        <br/>
                        <hr/><br/>
                        <vue-apex-charts ref="bar" type="bar" height="200" :options="paye_columnChart.chartOptions" :series="paye_columnChart.series"></vue-apex-charts>
                    </vx-card>
                </vs-col>
            </vs-row>
            <br/>
            <vx-card>
                <table class="table">
                    <tr>
                        <td></td>
                        <td align="right"><b>{{ getMonth() }}</b></td>
                        <td align="right"><b>Depuis Janvier {{ select_date.getFullYear() }}</b></td>
                    </tr>
                    <tr class="spacer">
                        <td class="spacer"></td>
                        <td class="spacer"></td>
                        <td class="spacer"></td>
                    </tr>
                    <tr>
                        <td>Espèces</td>
                        <td align="right">{{ millier(devise.nb_decimal, actu.espece.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                        <td align="right">{{ millier(devise.nb_decimal, janvier.espece.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                    </tr>
                    <tr>
                        <td>CB</td>
                        <td align="right">{{ millier(devise.nb_decimal, actu.cb.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                        <td align="right">{{ millier(devise.nb_decimal, janvier.cb.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                    </tr>
                    <tr>
                        <td>Chéques</td>
                        <td align="right">{{ millier(devise.nb_decimal, actu.cheque.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                        <td align="right">{{ millier(devise.nb_decimal, janvier.cheque.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                    </tr>
                    <tr>
                        <td>Prélévements</td>
                        <td align="right">{{ millier(devise.nb_decimal, actu.prele.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                        <td align="right">{{ millier(devise.nb_decimal, janvier.prele.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                    </tr>
                    <tr>
                        <td><b>Encaissement reçu</b></td>
                        <td align="right"><b>{{ millier(devise.nb_decimal, actu.enc_recu.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</b></td>
                        <td align="right"><b>{{ millier(devise.nb_decimal, janvier.enc_recu.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</b></td>
                    </tr>
                    <tr>
                        <td>Différés</td>
                        <td align="right">{{ millier(devise.nb_decimal, actu.differe.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                        <td align="right">{{ millier(devise.nb_decimal, janvier.differe.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</td>
                    </tr>
                    <tr>
                        <td><b>Encaissement Différés</b></td>
                        <td align="right"><b>{{ millier(devise.nb_decimal, actu.enc_differe.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</b></td>
                        <td align="right"><b>{{ millier(devise.nb_decimal, janvier.enc_differe.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</b></td>
                    </tr>
                    <tr class="spacer">
                        <td class="spacer"></td>
                        <td class="spacer"></td>
                        <td class="spacer"></td>
                    </tr>
                    <tr>
                        <td>Séances vendues</td>
                        <td align="right">{{ actu.seance }}</td>
                        <td align="right">{{ janvier.seance }}</td>
                    </tr>
                    <!--<tr>
                        <td>Séances consommées</td>
                        <td align="right">{{ actu.consommees }}</td>
                        <td align="right">{{ janvier.consommees }}</td>
                    </tr>-->
                    <tr class="spacer">
                        <td class="spacer"></td>
                        <td class="spacer"></td>
                        <td class="spacer"></td>
                    </tr>
                    <tr>
                        <td><b>Total</b></td>
                        <td align="right"><b>{{ millier(devise.nb_decimal, actu.total.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</b></td>
                        <td align="right"><b>{{ millier(devise.nb_decimal, janvier.total.toFixed(devise.nb_decimal)) }} {{ devise.symbol }}</b></td>
                    </tr>
                    <tr>
                        <td><b>Transactions</b></td>
                        <td align="right"><b>{{ actu.transaction }}</b></td>
                        <td align="right"><b>{{ janvier.transaction }}</b></td>
                    </tr>
                </table>
            </vx-card>
            <br/>
            <router-link to="/admin/export/contable" class="float-right"><vs-button class="btnx" type="filled" >Export comptable</vs-button></router-link>
        </template>
        <template v-else>
            <h2>Accès non autorisé.</h2>
        </template>
    </div>
</template>



<style lang="scss">
.encaissement_page{
    .table{
        width:100%;

        tr,td,th{
            height:30px;
        }
        .spacer{
            height:30px;
        }
    }
}
</style>

<script>

import Fdate from '@/assets/utils/fdate'
import Utils from '@/assets/utils/utils'

import Invoice from '@/database/models/invoice'
import Member from '@/database/models/member'

import autoRefresh from '@/assets/utils/autoRefresh'

import cDate from '@/components/app/date'


import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        cDate,
        VueApexCharts,
    },
    data(){
        return{
            select_date: new Date(),

            actu:{
                espece     : 0,
                cb         : 0,
                cheque     : 0,
                prele      : 0,
                enc_recu   : 0,
                differe    : 0,
                enc_differe: 0,
                seance     : 0,
                consommees : 0,
                total      : 0,
                transaction: 0,
            },
            janvier:{
                espece     : 0,
                cb         : 0,
                cheque     : 0,
                prele      : 0,
                enc_recu   : 0,
                differe    : 0,
                enc_differe: 0,
                seance     : 0,
                consommees : 0,
                total      : 0,
                transaction: 0,
            },

            admin:false,



            //DONUT GRAPH
            donutChart:
            {
                series: [1, 1],
                chartOptions:
                {
                    labels: ["Encaissements", "Différés"],
                    colors: [ '#76D46E', '#6EB0D4' ],
                    responsive: [
                    {
                        breakpoint: 480,
                        options:
                        {
                            legend:
                            {
                                show: false,
                                position: 'bottom'
                            },
                        }
                    }],
                    legend:{
                        show: false,
                    },
                },
            },


            //Bar chart
            paye_columnChart: {
                series: [{
                    name: 'Paiement',
                    data: [0, 0, 0, 0, 0]
                }],
                chartOptions:
                {
                    colors: [ '#6EB0D4' ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            endingShape: 'rounded',
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },

                    xaxis: {
                        categories: ['Espèces', 'Cartes', 'Chèques', 'Prélèvement', 'Différé'],
                    },
                    yaxis: {
                        title: {
                        show: false,
                        text: ""
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function(val){
                                return val
                            }
                        }
                    }
                }
            },
            devise: {}
        }
	},
	mounted:function()
	{
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			calcale_stat(this)
            this.RoleAdmin();
        })
		autoRefresh.refresh();
    },
    watch:{
        $route (){
            
        },
        select_date:function(){
            calcale_stat(this)
        },
    },
    methods:{
        millier:Utils.Millier,
		getMonth(){
            return Fdate.getMonth(this.select_date.toString())+' '+this.select_date.getFullYear()
        },

        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase()=="a" || mbr.type.toLowerCase()=="c";
            });
        }
    }
}


function calcale_stat( root ){
    Invoice.getAllMemory( function(fbuf)
    {
        //----- MONTH
        let tabMonth = []
        for( var i in fbuf )
        {
            let d = (new Date(fbuf[i].createAt)).getMonth()
            let y = (new Date(fbuf[i].createAt)).getFullYear()
            if( root.select_date.getMonth() == d )
            if( root.select_date.getFullYear() == y )
                tabMonth.push( fbuf[i] )
        }
        calculeList( root.actu, tabMonth )

        //---- YEAR
        let tabYear = []
        for( i in fbuf )
        {
            let y = (new Date(fbuf[i].createAt)).getFullYear()
            if( root.select_date.getFullYear() == y )
                tabYear.push( fbuf[i] )
        }
        calculeList( root.janvier, tabYear );
        updateDonut( root );
        updatePaiement( root );
    })
}
function calculeList( root, list )
{
    root.espece     = 0
    root.cb         = 0
    root.cheque     = 0
    root.prele      = 0
    root.enc_recu   = 0
    root.differe    = 0
    root.enc_differe= 0
    root.seance     = 0
    root.consommees = 0
    root.total      = 0
    root.transaction= 0


	//version avec les recu
	for( var i in list )
	{
        var buffer = list[i];

        root.espece  += Number(buffer.pay_money);
		root.cb      += Number(buffer.pay_cb);
		root.cheque  += Number(buffer.pay_check);
		root.differe += Number(buffer.pay_dif);
		root.seance  += Number(buffer.credit);

        // console.log(buffer)
        // if( buffer.type == "re" )
        let facturePrele = buffer.type == "fa" && buffer.mollie_id != null

        if( buffer.type == "re" || ( facturePrele && (buffer.mollie_id).search("tr_") == 0 ) )
            root.prele += Number(buffer.pay_prele);
		else
			root.differe += Number(buffer.pay_prele);

        root.transaction++;
        root.total += Number(buffer.pay_money) + Number(buffer.pay_cb) + Number(buffer.pay_check) + Number(buffer.pay_dif) + Number(buffer.pay_prele);
    }

    //?? ( dans code orignal )
    root.enc_differe = root.differe;
    root.enc_recu = root.espece + root.cb + root.cheque + root.prele;
}

function updateDonut( root )
{
    root.donutChart.series[0] = parseInt(root.actu.enc_recu);
    root.donutChart.series[1] = parseInt(root.actu.enc_differe);
    /*setTimeout(() => {
            root.$refs.donut.refresh();
    }, 1000);*/
}

function updatePaiement( root )
{
    root.paye_columnChart.series[0].data[0] = parseInt(root.actu.espece);
    root.paye_columnChart.series[0].data[1] = parseInt(root.actu.cb);
    root.paye_columnChart.series[0].data[2] = parseInt(root.actu.cheque);
    root.paye_columnChart.series[0].data[3] = parseInt(root.actu.prele);
    root.paye_columnChart.series[0].data[4] = parseInt(root.actu.enc_differe);
}


</script>